/* -----------------------------------------------------------------------------
 *
 * Components: Sticky Nav
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/utilities/common.css';

.component {
  display: block;
  z-index: 998;
  padding: 11px 0 10px 0;
  border-bottom: 1px solid var( --color-midnight-express );

  @media (--large) {
    padding: 16px 0;
  }
}

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.component__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 700;

  @media (--large) {
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;
  }

  span {
    &:first-of-type {
      margin: 0 20px;
      color: var( --color-white );
    }
  }
}

/* -----------------------------------------------------------------------------
 * Phone
 * ----------------------------------------------------------------------------- */

.component__phone {
  font-weight: bold;
  color: var( --color-electric-blue);

  @media (--large) {
    color: var( --color-white );
  }

  span {
    display: none;
    margin: 0 20px;
    color: var( --color-white );

    @media (--large) {
      display: inline;
    }
  }

  em {
    color: var( --color-white );
    font-style: normal;
    font-weight: bold;
  }
}

.component__hideOnMobile {
  display: none;

  @media (--medium) {
    display: flex;
  }
}

.component__hideOnMobileAndDesktop {
  display: none;

  @media (--medium) {
    display: flex;
  }

  @media (--large) {
    display: none;
  }
}
